// From great-gasby: https://github.com/curology/great-gatsby/blob/main/src/utils/string/index.ts
const dropTrailingSlash = (string: string): string =>
  string.charAt(string.length - 1) === '/' ? string.slice(0, -1) : string;

// Based on great-gatsby implementation: https://github.com/curology/great-gatsby/blob/main/src/utils/queryString/index.ts
export const generateQueryString = (queryObj: Record<string, string>) => {
  let queryStr = '';

  Object.keys(queryObj).forEach((key, index) => {
    const prefix = index === 0 ? '?' : '&';
    const value = queryObj[key];

    queryStr = `${queryStr}${prefix}${key}=${value}`;
  });

  return queryStr;
};

// Based on great-gatsby implementation: https://github.com/curology/great-gatsby/blob/main/src/utils/queryString/index.ts
const parseQueryString = () => {
  const queryPairs = {} as Record<string, string>;

  if (typeof window === 'undefined' || !window.location?.search) {
    return queryPairs;
  }

  const queryString = dropTrailingSlash(window.location.search.substring(1));

  const pairs = queryString.split('&');

  pairs.forEach((pair) => {
    const [key, value] = pair.split('=');

    queryPairs[key] = value;
  });

  return queryPairs;
};

/**
 * This function will check to see if a given query param is present and if not, return null.
 * All query parameter values are strings, but this function will conditionally return a boolean
 * or a number based on the value of the parameter.  If the query parameter does not fit the criteria
 * to be a boolean or a number, the string value will be returned.
 *
 * @param queryParam The name of the parameter
 */
export const getQueryParameterValue = (queryParam: string) => {
  const params = parseQueryString();

  if (params[queryParam]) {
    const paramValue = params[queryParam];

    if (paramValue === 'true' || paramValue === 'false') {
      return paramValue === 'true';
    }

    if (!Number.isNaN(Number(paramValue))) {
      return Number(paramValue);
    }

    return paramValue;
  }

  return undefined;
};
