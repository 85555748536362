export const CUROLOGY_HOME_TIMEZONE = 'America/Los_Angeles';
export const PHONE = 'phone';
export const VIDEO = 'video';
export type ConsultationsType = typeof PHONE | typeof VIDEO;

export const LIVE_CONSULTATION_STATUS = {
  pending: 'pending',
  scheduled: 'scheduled',
  completed: 'completed',
  closed: 'closed',
};

export const CONFIRMATION_MODAL = {
  cancel: 'cancel',
  new: 'new',
  modify: 'modify',
};
