import { action, makeObservable, observable } from 'mobx';

import URLS from 'jsx/constants/urls';
import { get } from 'jsx/utils/axiosWrapper';
import { useEffectOnce } from 'jsx/utils/hooks/useEffectOnce';
import useStores from 'jsx/utils/hooks/useStores';

interface Promotion {
  id: number;
  code: string;
  isActive: boolean;
  startsAt: string;
  endsAt: string;
  amountInDollars: number;
  treatmentCategoryId: number;
}

export interface Banner {
  defaultCopy: string;
  schedule: BannerSchedule[];
}

interface BannerSchedule {
  startDate: string;
  endDate: string;
  copy: string;
}

export interface Payment {
  defaultCopy: PaymentCopy;
  schedule: PaymentSchedule[];
}

interface PaymentSchedule {
  startDate: string;
  endDate: string;
  copy: PaymentCopy;
}

interface PaymentCopy {
  button: string;
  title: string;
  content: string;
}

export interface Shipment {
  defaultCopy: string;
  schedule: ShipmentSchedule[];
}

interface ShipmentSchedule {
  startDate: string;
  endDate: string;
  copy: string;
}

interface WinbackPromotion extends Promotion {
  discountAmount: number;
  paymentPageCtaCopy?: string;
  banner: Banner;
  payment: Payment;
  shipment: Shipment;
}

export default class PromotionStore {
  @observable
  promotion?: Promotion;

  @observable
  winbackPromotion?: WinbackPromotion;

  constructor() {
    makeObservable(this);
  }

  @action
  fetchPromotion = (code: string, silent = true) =>
    get<{ promotion?: Promotion }>(URLS.api.promotions({ code }), {
      validateStatus: silent ? () => true : undefined,
    }).then(({ data }) => {
      this.promotion = data.promotion;
    });

  @action
  fetchWinbackPromotion = (silent = true) =>
    get<{ promotion?: WinbackPromotion }>(URLS.api.winbackPromotions(), {
      validateStatus: silent ? () => true : undefined,
    }).then(({ data }) => {
      this.winbackPromotion = data.promotion;
    });
}

export const usePromotionStore = () => {
  const { promotionStore } = useStores<PromotionStore>();

  return { promotionStore };
};

export const usePromotion = (code: string) => {
  const {
    promotionStore: { promotion, fetchPromotion },
  } = usePromotionStore();

  useEffectOnce(() => {
    if (!promotion && code) {
      fetchPromotion(code);
    }
  });

  return promotion;
};

export const useWinbackPromotion = () => {
  const {
    promotionStore: { winbackPromotion, fetchWinbackPromotion },
  } = usePromotionStore();

  useEffectOnce(() => {
    if (!winbackPromotion) {
      fetchWinbackPromotion();
    }
  });

  return winbackPromotion;
};
