import styled from '@emotion/styled';
import { SPACER, GRID } from 'radiance-ui/lib/constants';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';

const navbarHeight = SPACER.x5large;
const remainingPageHeight = `calc(100vh - ${navbarHeight})`;

export const MainContainer = styled.div`
  background-color: ${({ theme }) => theme.COLORS.background};
  min-height: ${remainingPageHeight};
`;

export const ContentContainer = styled.div`
  margin: 0 auto;
  position: relative;
  padding: ${SPACER.large} ${SPACER.medium};
  max-width: ${GRID.xsContentMaxWidth};
  h2 {
    ${({ theme }) => TYPOGRAPHY_STYLE.body(theme)};
    position: relative;
    font-size: ${SPACER.large};
    line-height: 31.2px;
    margin-bottom: ${SPACER.medium};
    z-index: 1;
  }
`;

export const PageContainer = styled.div`
  min-height: ${remainingPageHeight};
  padding: ${SPACER.xlarge} ${GRID.standardPadding};
  width: 100%;
  position: relative;
`;
