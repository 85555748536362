import { AxiosError, AxiosResponse } from 'axios';
import { action, makeObservable, observable } from 'mobx';

import { get, post } from 'jsx/utils/axiosWrapper';
import storage from 'jsx/utils/storage';
import STORAGE_KEYS from 'jsx/constants/storageKeys';
import cookie from 'jsx/utils/cookie';
import COOKIE_KEYS from 'jsx/constants/cookieKeys';
import HTTP_STATUS_CODES from 'jsx/constants/httpStatusCodes';
import URLS from 'jsx/auth/constants/urls';
import {
  getAmplitudeDeviceId,
  trackEvent,
  trackRegistration,
} from 'jsx/utils/metrics';
import PatientModel, { UserResourceType } from 'jsx/models/patient';
import useStores from 'jsx/utils/hooks/useStores';

interface SignupSuccessResponse {
  user?: UserResourceType;
  isDeletingBrandAccount?: boolean;
  canTransferBrand?: boolean;
}

export const IS_DELETING_BRAND_ACCOUNT = 'isDeletingBrandAccount';
export const CAN_TRANSFER_BRAND = 'canTransferBrand';

export default class AuthStore {
  @observable
  storeUrl: string | null = null;

  @observable
  source: string | undefined;

  constructor() {
    makeObservable(this);
  }

  @action
  loginViaEmail(email: string) {
    return post(
      URLS.api.sendMagicLink({ method: 'email' }),
      { email },
      {
        // Disable automatic error respones for 403 errors which are expected
        // and indicate that the user does not use magic links.
        validateStatus: (status: number) =>
          status < 400 || status === HTTP_STATUS_CODES.forbidden,
      },
    );
  }

  @action
  login(data: { email: string; password: string }) {
    /** if storeUrl is present, lets update login type to eComm */
    trackEvent('Login Attempt', this.storeUrl ? { type: 'eComm' } : {});

    const ampDeviceId = getAmplitudeDeviceId();
    return post(URLS.api.login(), {
      ...data,
      ...(this.storeUrl && { storeUrl: this.storeUrl }),
      ...(this.source && { source: this.source }),
      ...(ampDeviceId && { amp_device_id: ampDeviceId }),
    }).then(this.onLoginSuccess, this.onLoginFailure);
  }

  @action
  onLoginSuccess = (response: AxiosResponse<{ redirect: string }>) => {
    const { data } = response;
    const { redirect } = data;

    /** if storeUrl is present, lets update login type to eComm */
    trackEvent('Login Success', this.storeUrl ? { type: 'eComm' } : {});

    return redirect;
  };

  @action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onLoginFailure = (error: AxiosError<{ data: any[] }>) => {
    const [status, statusText] = [
      error.response?.status,
      error.response?.statusText,
    ];

    const loginFailedEvent = 'Login Failed';

    if (status === HTTP_STATUS_CODES.tooManyAttempts) {
      trackEvent(loginFailedEvent, { type: statusText });
    } else if (status === HTTP_STATUS_CODES.locked) {
      trackEvent(loginFailedEvent, { type: 'Account is banned' });
    } else {
      trackEvent(loginFailedEvent, { type: 'Email or Password Invalid' });
    }

    throw error;
  };

  @action
  resetPassword(data: {
    password: string;
    password_confirmation: string;
    email: string;
    token: string;
  }) {
    return post(URLS.api.resetPassword(), data);
  }

  @action
  sendPasswordReset(email: string) {
    return post(URLS.api.sendPasswordReset(), { email });
  }

  @action
  signup(data: SignupData) {
    return post(URLS.api.register(), data).then(this.onSignupSuccess);
  }

  @action
  expressSignup(data: OtcSignupData) {
    const ampDeviceId = getAmplitudeDeviceId();

    return post(URLS.api.registerOtc(), {
      ...data,
      ...(ampDeviceId && { amp_device_id: ampDeviceId }),
    }).then(this.onOtcSignupSuccess);
  }

  @action
  onOtcSignupSuccess = (response: AxiosResponse<ExpressSignupResponse>) => {
    const { url } = response.data;

    // TODO: track registration like in onSignupSuccess

    return url;
  };

  @action
  onSignupSuccess = (response: AxiosResponse<SignupSuccessResponse>) => {
    const { user, isDeletingBrandAccount, canTransferBrand } = response.data;

    if (user) {
      // store registration data to be collected and tracked in Signup app
      storage.set(STORAGE_KEYS.registration, String(user.id));

      const patient = new PatientModel(user);
      trackRegistration(patient);

      // clean up stored signup cookies
      cookie.remove(COOKIE_KEYS.inviteToken);
      cookie.remove(COOKIE_KEYS.landingUrl);
      cookie.remove(COOKIE_KEYS.referrerUrl);
      cookie.remove(COOKIE_KEYS.referralCode);
      // 2023-02-16: Decided not to remove offer cookie so that user can retain their offer as long as possible when anonymous.
      // cookie.remove(COOKIE_KEYS.offer);
      cookie.remove(COOKIE_KEYS.offerSource);
      cookie.remove(COOKIE_KEYS.promotionCode);

      return patient;
    }

    if (isDeletingBrandAccount) {
      return IS_DELETING_BRAND_ACCOUNT;
    }
    if (canTransferBrand) {
      return CAN_TRANSFER_BRAND;
    }
  };

  @action
  fetchAuthenticationInformationData() {
    return get(URLS.api.authenticationInformation()).then(
      (response: AxiosResponse<AuthenticationInformation>) => {
        this.source = response.data.flow;
        return response.data.email;
      },
    );
  }
}

type UseAuthStore = () => { authStore: AuthStore };

export const useAuthStore: UseAuthStore = () => {
  const { authStore } = useStores();

  return { authStore };
};

interface SignupData {
  state: string | null;
  dob: string | null;
  email: string;
  password?: string;
  withBackendValidation?: boolean;
  disableInstantBrandTransfer?: boolean;
}

interface AuthenticationInformation {
  email: string;
  flow: string;
}

interface OtcSignupData {
  acceptedTOS: boolean;
  email: string;
  password?: string;
  withBackendValidation?: boolean;
}

interface ExpressSignupResponse {
  url: string; // the otc store login redirect url
}
