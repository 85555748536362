import React, { useState } from 'react';

import Loading from 'jsx/components/loading';
import { InitializableType } from 'jsx/mixins';
import { useEffectOnce } from 'jsx/utils/hooks/useEffectOnce';

/*

use `ensureHooksInitialized` to initialize stores for
function components that rely on hooks for store access.

const Example = () => {
  const { myStore } = useMyStore()
  return <h1>{myStore.helloWorld}</h1>
}

export default ensureHooksInitialized(useMyStore)(observer(Example))

*/

interface InitializableStoreType {
  [storeName: string]: InitializableType;
}

type UseStoreType = () => InitializableStoreType;

interface EnsureHooksOptionsType {
  alwaysInitializeOnMount?: boolean;
  initializeSynchronously?: boolean;
}

const ensureHooksHelper = ({
  alwaysInitializeOnMount = false,
  initializeSynchronously = false,
}: EnsureHooksOptionsType) => {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return (...hooks: UseStoreType[]) =>
    <Props extends {}>(Component: React.FC<Props>) => {
      const Wrapper: React.FC<Props> = (props: Props) => {
        const stores = hooks.reduce(
          (obj, hook) => ({ ...obj, ...hook() }),
          {} as InitializableStoreType,
        );
        const allStoresInitialized = () =>
          Object.values(stores).every((store) => store.shouldInitialize);
        const [isInitialized, setIsInitialized] = useState(
          () => allStoresInitialized() && !alwaysInitializeOnMount, // If all the stores are already initialized default to true
        );
        const initializeSync = () => {
          return Object.keys(stores).reduce((promiseChain, storeKey) => {
            const store = stores[storeKey];
            if (alwaysInitializeOnMount) {
              store.initializedAt = null;
            }

            if (!store || typeof store.initialize !== 'function') {
              return promiseChain;
            }
            return promiseChain.then(() => store.initialize());
          }, Promise.resolve() as Promise<void>);
        };

        const getInitializers = () => {
          return Object.keys(stores)
            .map((storeKey) => {
              const store = stores[storeKey];
              if (alwaysInitializeOnMount) {
                store.initializedAt = null;
              }

              // If the provider does not pass in a store of the expected type,
              // it might not be initializable.
              if (!store || typeof store.initialize !== 'function') {
                return false;
              }

              return store.initialize();
            })
            .filter(Boolean);
        };

        useEffectOnce(() => {
          if (initializeSynchronously) {
            initializeSync().then(() => {
              setIsInitialized(true);
            });
            return () => undefined;
          }

          const promises = getInitializers();
          Promise.all(promises).then(() => {
            setIsInitialized(true);
          });
        });

        // eslint-disable-next-line react/jsx-props-no-spreading
        return isInitialized ? <Component {...props} /> : <Loading />;
      };

      return Wrapper;
    };
};

export const ensureHooksInitialized = ensureHooksHelper({});

/**
 * Works just like ensureHooksInitialized except that any hooks passed will be
 * re-initialized every time the component mounts instead of just once.
 */
export const ensureFreshHooks = ensureHooksHelper({
  alwaysInitializeOnMount: true,
});

/**
 * Works just like ensureFreshHooks except that any hooks passed will be
 * resolved synchronously. This helper is useful when there are dependencies
 * between the stores.
 */
export const ensureFreshHooksInitializedSync = ensureHooksHelper({
  alwaysInitializeOnMount: true,
  initializeSynchronously: true,
});
