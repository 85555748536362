export default {
  amplitudeDeviceId: 'amp_device_id',
  bannerPromotionCode: 'bannerPromotionCode',
  cartId: 'cartId',
  curologyUuid: 'curologyUuid',
  inviteToken: 'inviteToken',
  isOtcSignupUser: 'isOtcSignupUser',
  landingUrl: 'landingPageUrl',
  landingVariant: 'landingPageVariant',
  ldUuid: 'ldUuid',
  messagesV1: 'messages-v1',
  offer: 'offer',
  offerSource: 'offerSource',
  promotionCode: 'promotionCode',
  referralCode: 'referralCode',
  referralTrackingUrl: 'referralTrackingUrl',
  referrerUrl: 'httpReferrerUrl',
  trackFullRegistrationInSignup: 'trackFullRegistrationInSignup',
  trackingOptOut: 'trackingOptOut',
  treatmentCategories: 'treatmentCategories',
  treatmentCategoryIds: 'treatmentCategoryIds',
};
