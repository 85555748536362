import {
  observable,
  set as mobxSet,
  computed,
  action,
  makeObservable,
} from 'mobx';
import moment from 'moment';
import { AxiosResponse } from 'axios';

import { get, post } from 'jsx/utils/axiosWrapper';
import { Initializable } from 'jsx/mixins';
import BaseStore from 'jsx/stores/baseStore';
import {
  trackEligibilitySubmission,
  trackEligibilityFailure,
} from 'jsx/utils/metrics';
import URLS from 'jsx/auth/constants/urls';
import { INELIGIBLE_REASONS } from 'jsx/auth/constants/eligibility';
import useStores from 'jsx/utils/hooks/useStores';
import { isDefined } from 'jsx/utils/isDefined';
import CONFIG from 'jsx/constants/config';
import { TREATMENT_CATEGORIES } from 'jsx/types/treatmentCategories';

import {
  REASON_KEY,
  ZIP_KEY,
  DOB_KEY,
  STATE_KEY,
  VIEW_ORDER,
  ACCEPTED_TOS_KEY,
  EXPEDITED_ELIGIBILITY_KEY,
} from './constants';
import { PatientInitiatedTransferData } from '../../views/eligibility/funnelBrandTransfer/types';
import {
  getFromStorage,
  removeFromStorage,
  setWithNullHandling,
} from './helpers';
import { RegistrationEligibilityResponseType } from '../../types';

class EligibilityStore extends Initializable(BaseStore) {
  @observable
  ineligibleReason: string | null = null;

  @observable
  dateOfBirth: string | null = null;

  @observable
  acceptedTOS = false;

  @observable
  state: string | null = null;

  @observable
  patientBrandTransferData: PatientInitiatedTransferData | null = null;

  @observable
  isEligible: boolean | null = null;

  constructor() {
    super();
    makeObservable(this);
  }

  @action
  onInitialize = () => {
    const ineligibleReason = getFromStorage(REASON_KEY);
    const dateOfBirth = getFromStorage(DOB_KEY);
    const state = getFromStorage(STATE_KEY);
    const tosCookie = getFromStorage(ACCEPTED_TOS_KEY);
    const acceptedTOS = Boolean(Number(tosCookie)) || tosCookie === 'true';
    const isEligible = Boolean(
      Number(getFromStorage(EXPEDITED_ELIGIBILITY_KEY)),
    );

    this.hydrate({
      ineligibleReason,
      dateOfBirth,
      state,
      acceptedTOS,
      isEligible,
    });
    return Promise.resolve();
  };

  @action
  hydrate = (data: {
    ineligibleReason?: string | null;
    dateOfBirth?: string | null;
    state?: string | null;
    acceptedTOS?: boolean;
    patientBrandTransferData?: PatientInitiatedTransferData | null;
    isEligible?: boolean | null;
  }) => {
    mobxSet(this, data);
  };

  @action
  setGuestState = (state: string | null | undefined) => {
    setWithNullHandling(STATE_KEY, state);
    this.hydrate({ state });
  };

  @action
  setIneligibleReason = (ineligibleReason: string | null) => {
    setWithNullHandling(REASON_KEY, ineligibleReason);
    this.hydrate({ ineligibleReason });
  };

  @action
  setAcceptedTos = (acceptedTOS: boolean) => {
    setWithNullHandling(ACCEPTED_TOS_KEY, String(Number(acceptedTOS)));
    this.hydrate({ acceptedTOS });
  };

  @action
  setDateOfBirth = (dateOfBirth: string | null) => {
    setWithNullHandling(DOB_KEY, dateOfBirth);
    this.hydrate({ dateOfBirth });
  };

  @action
  setIsEligible = (isEligible: boolean) => {
    setWithNullHandling('eligible', String(Number(isEligible)));
    this.hydrate({ isEligible });
  };

  @computed
  get is13OrOlder() {
    const dob = moment(this.dateOfBirth, 'MM/DD/YYYY');
    const thirteenYearsAgo = moment().subtract(13, 'years');
    return (
      dob.isBefore(thirteenYearsAgo) || dob.isSame(thirteenYearsAgo, 'day')
    );
  }

  @computed
  get canRegister() {
    return !!this.canViewLocation(URLS.register);
  }

  @computed
  get isFromEligibleState() {
    return isDefined(this.state);
  }

  @computed
  get hasEligibleDOB() {
    return (
      !!this.dateOfBirth &&
      this.ineligibleReason !== INELIGIBLE_REASONS.ineligibleAge
    );
  }

  @computed
  get isIneligible() {
    return !!this.ineligibleReason;
  }

  @computed
  get farthestView() {
    if (CONFIG.isAgencyApp && this.isEligible) {
      return URLS.register;
    }

    if (!this.isFromEligibleState || !this.hasEligibleDOB) {
      return URLS.welcome;
    }

    return URLS.register;
  }

  @computed
  get registrationData() {
    return {
      dob: this.dateOfBirth,
      acceptedTOS: this.acceptedTOS,
      state: this.state,
      isEligible: this.isEligible,
    };
  }

  @computed
  get patientBrandTransferResource() {
    return this.patientBrandTransferData;
  }

  clearStoredAttributes = () => {
    removeFromStorage(REASON_KEY);
    removeFromStorage(ZIP_KEY);
    removeFromStorage(DOB_KEY);
    removeFromStorage(STATE_KEY);
    removeFromStorage(ACCEPTED_TOS_KEY);
  };

  @action
  getDateOfBirthEligibility = (data: { dob: string; state?: string }) => {
    this.setGuestState(data.state);
    this.setDateOfBirth(data.dob);
    this.hydrate({
      dateOfBirth: data.dob,
      state: data.state ?? this.state,
      acceptedTOS: this.acceptedTOS,
    });
    trackEligibilitySubmission();

    return post(URLS.api.registrationEligibility.evaluate(), {
      birthdate: this.dateOfBirth,
      state: this.state,
      hasAcceptedTerms: this.acceptedTOS,
      treatmentCategories: TREATMENT_CATEGORIES.skin,
    }).then((response) => {
      const eligibilityData =
        response.data as RegistrationEligibilityResponseType;
      if (!eligibilityData.ineligibleReason) {
        this.setIneligibleReason(null);
        return response;
      }

      if (eligibilityData.ineligibleReason?.reason === 'ineligible_age') {
        this.setIneligibleReason(INELIGIBLE_REASONS.ineligibleAge);
        trackEligibilityFailure({
          reason: CONFIG.isAgencyApp
            ? 'Agency user under 18'
            : 'Curology user under 13',
          dob: this.dateOfBirth,
        });

        return Promise.reject(this.ineligibleReason);
      }

      return Promise.reject();
    });
  };

  @action
  completeFunnelBrandTransfer = () => {
    return post(URLS.api.registrationEligibility.storeFunnelBrandTransfer(), {
      dob: this.dateOfBirth ?? undefined,
      state: this.state ?? undefined,
      acceptedTOS: this.acceptedTOS,
    });
  };

  @action
  fetchFunnelBrandTransferData = () => {
    return get(
      URLS.api.registrationEligibility.showFunnelBrandTransfer({
        dob: this.dateOfBirth ?? undefined,
        state: this.state ?? undefined,
        acceptedTOS: this.acceptedTOS,
      }),
    ).then((response: AxiosResponse<PatientInitiatedTransferData>) => {
      this.patientBrandTransferData = response.data;
    });
  };

  saveEmail = (email: string) => {
    const dob = this.dateOfBirth
      ? moment(this.dateOfBirth, 'MM/DD/YYYY').format('YYYY-MM-DD')
      : null;
    const data = {
      email,
      dob,
      reason: this.ineligibleReason,
      state: this.state,
    };

    return post(URLS.api.emailEligibility(), data);
  };

  private canViewLocation = (location: string) => {
    const farthestViewableLocation = this.farthestView;

    const locationIndex = VIEW_ORDER.indexOf(location);
    const farthestViewableIndex = VIEW_ORDER.indexOf(farthestViewableLocation);

    if (farthestViewableIndex < 0) {
      return true;
    }

    return farthestViewableIndex >= locationIndex;
  };
}

export const useEligibilityStore = () => {
  const { eligibilityStore } = useStores<EligibilityStore>();

  return { eligibilityStore };
};

export default EligibilityStore;
