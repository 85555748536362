/* eslint-disable sonarjs/no-duplicate-string */
import uniq from 'lodash.uniq';

export const PRODUCT_CATEGORY = 'product';

export type ItemCategoryType = typeof PRODUCT_CATEGORY;

export const PRESCRIPTION_BOTTLE_TYPE = 'prescription bottle';
export const DARK_SPOT_SERUM_TYPE = 'dark_spot_serum';
export const HAIR_FORMULA_TYPE = 'hair_formula';
export const BARRIER_BALM_TYPE = 'barrier_balm';
export const FUTURE_FORMULA_TYPE = 'future_formula';
export const DARK_SPOT_FORMULA_TYPE = 'dark_spot_formula';
export const EYE_FORMULA_TYPE = 'eye_formula';
export const MOISTURIZER_TYPE = 'moisturizer';
export const CLEANSER_TYPE = 'cleanser';
export const HYDROCOLLOID_TYPE = 'hydrocolloid';
export const ACNE_BODY_WASH_TYPE = 'acne_body_wash';
export const SUNSCREEN_TYPE = 'sunscreen';
export const LIP_BALM_TYPE = 'lip_balm';
export const MAKEUP_REMOVER_TYPE = 'makeup_remover';
export const DIGITAL_TYPE = 'digital';
export const HYDROSERUM_EYE_STICK_TYPE = 'hydroserum_eye_stick';
export const MILKY_HYDRATION_CONCENTRATE_TYPE = 'milky_hydration_concentrate';
export const EXFOLIANT_TYPE = 'exfoliant';
export const PREPACKAGED_KIT_TYPE = 'prepackaged_kit';
export const HYDRATION_SERUM_TYPE = 'hydration_serum';
export const SKINCARE_TOOL_TYPE = 'skincare_tool';
export const VITAMIN_C_SERUM_TYPE = 'vitamin_c_serum';
export const RICH_RESTORATION_BALM_TYPE = 'rich_restoration_balm';

export const HYDRO_PLUS_EYE_GEL_TYPE = 'hydro_plus_eye_gel';

export const CUSTOM_FORMULA_PRODUCT = 'custom-formula';
export const FUTURE_PROOF_FORMULA_PRODUCT = 'curology-future-proof';
export const BODY_CLEANSE_PRODUCT = 'body-cleanse';
export const DEEP_CLEANSE_PRODUCT = 'deep-cleanse';
export const FUTURE_FORMULA_PRODUCT = 'future-formula';
export const DARK_SPOT_FORMULA_PRODUCT = 'dark-spot-formula';
export const EYE_FORMULA_PRODUCT = 'eye-formula';
export const HAIR_FORMULA_PRODUCT = 'hair-formula';
export const AGENCY_HAIR_FORMULA_PRODUCT = 'agency-hair-formula';

export const CUROLOGY_FORMULA_PRODUCTS = [
  CUSTOM_FORMULA_PRODUCT,
  FUTURE_PROOF_FORMULA_PRODUCT,
  BODY_CLEANSE_PRODUCT,
  DEEP_CLEANSE_PRODUCT,
  HAIR_FORMULA_PRODUCT,
] as const;

export type CurologyFormulaProductsType =
  typeof CUROLOGY_FORMULA_PRODUCTS[number];

export const AGENCY_FORMULA_PRODUCTS = [
  FUTURE_FORMULA_PRODUCT,
  DARK_SPOT_FORMULA_PRODUCT,
  EYE_FORMULA_PRODUCT,
  AGENCY_HAIR_FORMULA_PRODUCT,
] as const;

export type AgencyFormulaProductsType = typeof AGENCY_FORMULA_PRODUCTS[number];

export const PRESCRIPTION_FORMULA_PRODUCTS = [
  ...CUROLOGY_FORMULA_PRODUCTS,
  ...AGENCY_FORMULA_PRODUCTS,
] as const;

export type PrescriptionFormulaProductsType =
  typeof PRESCRIPTION_FORMULA_PRODUCTS[number];

export const ABBREVIATED_FORMULA_PRODUCTS: {
  [index in PrescriptionFormulaProductsType]: string;
} = {
  [CUSTOM_FORMULA_PRODUCT]: 'CF',
  [FUTURE_PROOF_FORMULA_PRODUCT]: 'FP',
  [BODY_CLEANSE_PRODUCT]: 'BC',
  [DEEP_CLEANSE_PRODUCT]: 'DC',
  [FUTURE_FORMULA_PRODUCT]: 'FF',
  [DARK_SPOT_FORMULA_PRODUCT]: 'DSF',
  [EYE_FORMULA_PRODUCT]: 'EF',
  [HAIR_FORMULA_PRODUCT]: 'HF',
  [AGENCY_HAIR_FORMULA_PRODUCT]: 'AHF',
} as const;

export const CUROLOGY_FORMULA_PRODUCT_NAMES: {
  [index in CurologyFormulaProductsType]: string;
} = {
  [CUSTOM_FORMULA_PRODUCT]: 'Custom Formula',
  [FUTURE_PROOF_FORMULA_PRODUCT]: 'Future Proof Formula',
  [BODY_CLEANSE_PRODUCT]: 'Body Cleanse',
  [DEEP_CLEANSE_PRODUCT]: 'Deep Cleanse',
  // eslint-disable-next-line sonarjs/no-duplicate-string
  [HAIR_FORMULA_PRODUCT]: 'Hair Formula',
} as const;

export const AGENCY_FORMULA_PRODUCT_NAMES: {
  [index in AgencyFormulaProductsType]: string;
} = {
  [FUTURE_FORMULA_PRODUCT]: 'Future Formula',
  [DARK_SPOT_FORMULA_PRODUCT]: 'Dark Spot Formula',
  [EYE_FORMULA_PRODUCT]: 'Eye Formula',
  // eslint-disable-next-line sonarjs/no-duplicate-string
  [AGENCY_HAIR_FORMULA_PRODUCT]: 'Hair Formula',
} as const;

export const FORMULA_PRODUCT_NAMES: {
  [index in PrescriptionFormulaProductsType]: string;
} = {
  ...CUROLOGY_FORMULA_PRODUCT_NAMES,
  ...AGENCY_FORMULA_PRODUCT_NAMES,
} as const;

export const CUROLOGY_FORMULA_TYPES = [
  PRESCRIPTION_BOTTLE_TYPE,
  HAIR_FORMULA_TYPE,
] as const;

export type CurologyFormulaTypeType = typeof CUROLOGY_FORMULA_TYPES[number];

export const AGENCY_FORMULA_TYPES = [
  FUTURE_FORMULA_TYPE,
  DARK_SPOT_FORMULA_TYPE,
  EYE_FORMULA_TYPE,
  HAIR_FORMULA_TYPE,
] as const;

export type AgencyFormulaTypeType = typeof AGENCY_FORMULA_TYPES[number];

export const PRESCRIPTION_FORMULA_TYPES = [
  ...CUROLOGY_FORMULA_TYPES,
  ...AGENCY_FORMULA_TYPES,
] as const;

export type PrescriptionFormulaType = typeof PRESCRIPTION_FORMULA_TYPES[number];

const CUROLOGY_PRODUCT_ITEM_TYPES = [
  ...CUROLOGY_FORMULA_TYPES,
  MOISTURIZER_TYPE,
  CLEANSER_TYPE,
  HYDROCOLLOID_TYPE,
  ACNE_BODY_WASH_TYPE,
  SUNSCREEN_TYPE,
  LIP_BALM_TYPE,
  MAKEUP_REMOVER_TYPE,
  BARRIER_BALM_TYPE,
  DARK_SPOT_SERUM_TYPE,
  EXFOLIANT_TYPE,
  PREPACKAGED_KIT_TYPE,
  HYDRATION_SERUM_TYPE,
  SKINCARE_TOOL_TYPE,
  VITAMIN_C_SERUM_TYPE,
  HYDRO_PLUS_EYE_GEL_TYPE,
] as const;

const AGENCY_PRODUCT_ITEM_TYPES = [
  ...AGENCY_FORMULA_TYPES,
  MOISTURIZER_TYPE,
  CLEANSER_TYPE,
  HYDROSERUM_EYE_STICK_TYPE,
  MILKY_HYDRATION_CONCENTRATE_TYPE,
  RICH_RESTORATION_BALM_TYPE,
] as const;

const PRODUCT_ITEM_TYPES = uniq([
  ...CUROLOGY_PRODUCT_ITEM_TYPES,
  ...AGENCY_PRODUCT_ITEM_TYPES,
]);

export type ProductItemTypeType = typeof PRODUCT_ITEM_TYPES[number];

export const ITEM_TYPES = [...PRODUCT_ITEM_TYPES, DIGITAL_TYPE] as const;

export type ItemTypeType = typeof ITEM_TYPES[number];

export const CUROLOGY_FORMULA_TYPE_NAMES: {
  [index in CurologyFormulaTypeType]: string;
} = {
  [PRESCRIPTION_BOTTLE_TYPE]: 'Custom Formula',
  // eslint-disable-next-line sonarjs/no-duplicate-string
  [HAIR_FORMULA_TYPE]: 'Hair Formula',
} as const;

export const AGENCY_FORMULA_TYPE_NAMES: {
  [index in AgencyFormulaTypeType]: string;
} = {
  [FUTURE_FORMULA_TYPE]: 'Future Formula',
  [DARK_SPOT_FORMULA_TYPE]: 'Dark Spot Formula',
  [EYE_FORMULA_TYPE]: 'Eye Formula',
  [HAIR_FORMULA_TYPE]: 'Hair Formula',
} as const;

export const FORMULA_TYPE_NAMES: {
  [index in PrescriptionFormulaType]: string;
} = {
  ...CUROLOGY_FORMULA_TYPE_NAMES,
  ...AGENCY_FORMULA_TYPE_NAMES,
} as const;

export const PRODUCT_ITEM_TYPE_NAMES: {
  [index in ProductItemTypeType]: string;
} = {
  ...FORMULA_TYPE_NAMES,
  [MOISTURIZER_TYPE]: 'Moisturizer',
  [CLEANSER_TYPE]: 'Cleanser',
  [HYDROCOLLOID_TYPE]: 'Spot patches',
  [ACNE_BODY_WASH_TYPE]: 'Acne body wash',
  [SUNSCREEN_TYPE]: 'Sunscreen',
  [LIP_BALM_TYPE]: 'Lip balm',
  [MAKEUP_REMOVER_TYPE]: 'Makeup remover',
  [BARRIER_BALM_TYPE]: 'Barrier Balm',
  [DARK_SPOT_SERUM_TYPE]: 'Dark Spot Serum',
  [HYDROSERUM_EYE_STICK_TYPE]: 'HydroSerum Eye Stick',
  [MILKY_HYDRATION_CONCENTRATE_TYPE]: 'Milky Hydration Concentrate',
  [EXFOLIANT_TYPE]: 'Exfoliant',
  [PREPACKAGED_KIT_TYPE]: 'Prepackaged Kit',
  [HYDRATION_SERUM_TYPE]: 'Hydration Serum',
  [SKINCARE_TOOL_TYPE]: 'Skincare Tool',
  [VITAMIN_C_SERUM_TYPE]: 'Vitamin C Serum',
  [RICH_RESTORATION_BALM_TYPE]: 'Rich Restoration Balm',
  [HYDRO_PLUS_EYE_GEL_TYPE]: 'Hydro+ Eye Gel',
} as const;

export const SMALL_SIZE = 'small';
export const LARGE_SIZE = 'large';

export type SubscriptionItemSizeType = 'large' | 'small' | null;

const CUROLOGY_PRODUCT_ITEM_IDS = {
  smallCustomFormula: 1,
  largeCustomFormula: 2,
  smallCustomFormulaWithDsf: 1,
  largeCustomFormulaWithDsf: 2,
  smallCleanser: 3,
  largeCleanser: 4,
  smallMoisturizer: 5,
  largeMoisturizer: 6,
  smallRichMoisturizer: 23,
  largeRichMoisturizer: 24,
  smallSpotPatches: 25,
  largeSpotPatches: 26,
  smallAcneBodyWash: 42,
  largeAcneBodyWash: 43,
  smallSunscreen: 62,
  largeSunscreen: 63,
  originalLipBalm: 82,
  mintLipBalm: 83,
  passionFruitLipBalm: 84,
  spfLipBalm: 111,
  vanillaLipBalm: 85,
  smallMicellarMakeupRemover: 97,
  largeMicellarMakeupRemover: 98,
  meshBagAndCottonRounds: 105,
  smallCurologyFutureProof: 106,
  largeCurologyFutureProof: 107,
  smallDeepCleanse: 114,
  largeDeepCleanse: 115,
  largeAcneCleanser: 123,
  smallAcneCleanser: 124,
  hairFormula: 131,
  largeBarrierBalm: 135,
  smallBarrierBalm: 136,
  largeDarkSpotSerum: 149,
  jumboMicellarMakeupRemover: 152,
  largeLiquidPoreExfoliant: 153,
  largeSpotPatchClusters: 154,
  starterSet: 155,
  largeHydrationSerum: 156,
  largeBodyCleanse: 160,
  largeVitaminCSerum: 161,
  funfettiLipBalm: 164,
  sugarAndSpiceLipBalm: 144,
  largeHydroPlusEyeGel: 168,
  largeHydroPlusSpfMoisturizer: 169,
  smallHydroPlusGelCreamMoisturizer: 170,
  largeHydroPlusGelCreamMoisturizer: 171,
  smallHydroPlusFoamingGelCleanser: 172,
  largeHydroPlusFoamingGelCleanser: 173,
  customFormula9g: 174,
  customFormula18g: 175,
  futureProof9g: 176,
  futureProof18g: 177,
} as const;

export const CUROLOGY_ITEM_IDS = {
  ...CUROLOGY_PRODUCT_ITEM_IDS,
} as const;

const AGENCY_PRODUCT_ITEM_IDS = {
  smallFutureFormula: 48,
  largeFutureFormula: 49,
  smallDarkSpotFormula: 50,
  largeDarkSpotFormula: 51,
  smallCreamOilCleanser: 66,
  largeCreamOilCleanser: 67,
  smallWeightlessWhippedMoisturizer: 68,
  largeWeightlessWhippedMoisturizer: 69,
  packetteSilkScreen: 128,
  largeLightMediumTintSilkScreen: 129,
  largeMediumDeepTintSilkScreen: 130,
  agencyHairFormula: 134,
  smallEyeFormula: 132,
  largeEyeFormula: 133,
  largeHydroSerumEyeStick: 150,
  largeMilkyHydrationConcentrate: 151,
  largeRichRestorationBalm: 167,
  futureFormula9g: 166,
  futureFormula18g: 178,
} as const;

export const PATIENT_EDUCATION_INSTRUCTION_INSERT_IDS = {
  universalPEI: 118,
};

export const AGENCY_ITEM_IDS = AGENCY_PRODUCT_ITEM_IDS;

export const PRODUCT_ITEM_IDS = {
  ...CUROLOGY_PRODUCT_ITEM_IDS,
  ...AGENCY_PRODUCT_ITEM_IDS,
} as const;

export const ITEM_IDS = {
  ...CUROLOGY_ITEM_IDS,
  ...AGENCY_ITEM_IDS,
  ...PATIENT_EDUCATION_INSTRUCTION_INSERT_IDS,
} as const;

export type ItemKeyType = keyof typeof ITEM_IDS;
export type ProductItemKeyType = keyof typeof PRODUCT_ITEM_IDS;

export type ItemIdType = typeof ITEM_IDS[ItemKeyType];

export const CUROLOGY_ITEM_NAMES: {
  [index in keyof typeof CUROLOGY_ITEM_IDS]: string;
} = {
  smallCustomFormula: 'Small Custom Formula',
  largeCustomFormula: 'Large Custom Formula',
  customFormula9g: 'Small Custom Formula',
  customFormula18g: 'Large Custom Formula',
  smallCustomFormulaWithDsf: 'Small Custom Formula',
  largeCustomFormulaWithDsf: 'Large Custom Formula',
  smallCleanser: 'Small cleanser',
  largeCleanser: 'Large cleanser',
  smallMoisturizer: 'Small moisturizer',
  largeMoisturizer: 'Large moisturizer',
  smallRichMoisturizer: 'Small rich moisturizer',
  largeRichMoisturizer: 'Large rich moisturizer',
  smallSpotPatches: 'Small emergency spot patches',
  largeSpotPatches: 'Large emergency spot patches',
  smallAcneBodyWash: 'Small acne body wash',
  largeAcneBodyWash: 'Large acne body wash',
  smallSunscreen: 'Small sunscreen',
  largeSunscreen: 'Large sunscreen',
  originalLipBalm: 'The lip balm (original)',
  mintLipBalm: 'The lip balm (mint)',
  passionFruitLipBalm: 'The lip balm (passion fruit)',
  spfLipBalm: 'The lip balm (SPF 30)',
  vanillaLipBalm: 'The lip balm (vanilla)',
  funfettiLipBalm: 'The lip balm (funfetti)',
  sugarAndSpiceLipBalm: 'The lip balm (sugar & spice)',
  smallMicellarMakeupRemover: 'Small micellar makeup remover',
  largeMicellarMakeupRemover: 'Large micellar makeup remover',
  smallCurologyFutureProof: 'Small Curology Future-Proof',
  largeCurologyFutureProof: 'Large Curology Future-Proof',
  futureProof9g: 'Small Curology Future-Proof',
  futureProof18g: 'Large Curology Future-Proof',
  smallDeepCleanse: 'Small Deep Cleanse',
  largeDeepCleanse: 'Large Deep Cleanse',
  largeAcneCleanser: 'Large Acne Cleanser',
  smallAcneCleanser: 'Small Acne Cleanser',
  // eslint-disable-next-line sonarjs/no-duplicate-string
  hairFormula: 'Hair Formula',
  smallBarrierBalm: 'Small Barrier Balm',
  largeBarrierBalm: 'Large Barrier Balm',
  largeDarkSpotSerum: 'Large Dark Spot Serum',
  largeLiquidPoreExfoliant: 'Large Liquid Pore Exfoliant',
  largeSpotPatchClusters: 'Large emergency spot patches',
  starterSet: 'Starter Set',
  largeHydrationSerum: 'Large Hydration Serum',
  jumboMicellarMakeupRemover: 'Jumbo Micellar Makeup Remover',
  meshBagAndCottonRounds: 'Reusable Cotton Rounds',
  largeBodyCleanse: 'Large Body Cleanse',
  largeVitaminCSerum: 'Large Vitamin C Serum',
  largeHydroPlusEyeGel: 'Large Hydro+ Eye Gel',
  largeHydroPlusSpfMoisturizer:
    'Large Hydro+ Daily Moisturizer Broad Spectrum SPF 30',
  smallHydroPlusGelCreamMoisturizer: 'Small Hydro+ Gel-Cream Moisturizer',
  largeHydroPlusGelCreamMoisturizer: 'Large Hydro+ Gel-Cream Moisturizer',
  smallHydroPlusFoamingGelCleanser: 'Small Hydro+ Foaming Gel Cleanser',
  largeHydroPlusFoamingGelCleanser: 'Large Hydro+ Foaming Gel Cleanser',
} as const;

export const AGENCY_ITEM_NAMES: {
  [index in keyof typeof AGENCY_ITEM_IDS]: string;
} = {
  smallFutureFormula: 'Small Future Formula',
  largeFutureFormula: 'Large Future Formula',
  futureFormula9g: 'Small Future Formula',
  futureFormula18g: 'Large Future Formula',
  smallDarkSpotFormula: 'Small Dark Spot Formula',
  largeDarkSpotFormula: 'Large Dark Spot Formula',
  smallCreamOilCleanser: 'Small Cream Oil Cleanser',
  largeCreamOilCleanser: 'Large Cream Oil Cleanser',
  smallWeightlessWhippedMoisturizer: 'Small Weightless Whipped Moisturizer',
  largeWeightlessWhippedMoisturizer: 'Large Weightless Whipped Moisturizer',
  packetteSilkScreen: 'Sample SilkScreen',
  largeLightMediumTintSilkScreen: 'Large SilkScreen (light-to-medium)',
  largeMediumDeepTintSilkScreen: 'Large SilkScreen (medium-to-deep)',
  // eslint-disable-next-line sonarjs/no-duplicate-string
  agencyHairFormula: 'Hair Formula',
  smallEyeFormula: 'Small Eye Formula',
  largeEyeFormula: 'Large Eye Formula',
  largeHydroSerumEyeStick: 'Large HydroSerum Eye Stick',
  largeMilkyHydrationConcentrate: 'Large Milky Hydration Concentrate',
  largeRichRestorationBalm: 'Large Rich Restoration Balm',
} as const;

export const PATIENT_EDUCATION_INSTRUCTION_INSERTS_NAMES: {
  [index in keyof typeof PATIENT_EDUCATION_INSTRUCTION_INSERT_IDS]: string;
} = {
  universalPEI: 'Universal PEI Insert',
} as const;

export const ITEM_NAMES: {
  [index in ItemKeyType]: string;
} = {
  ...CUROLOGY_ITEM_NAMES,
  ...AGENCY_ITEM_NAMES,
  ...PATIENT_EDUCATION_INSTRUCTION_INSERTS_NAMES,
} as const;

export const ITEM_SUBTEXT: {
  [id in valueof<typeof ITEM_IDS>]?: string;
} = {
  // Curology
  [ITEM_IDS.largeCustomFormula]: 'Box ships for free every 2 months',
  [ITEM_IDS.smallCustomFormula]: 'Box ships for $4.95 every month',
  [ITEM_IDS.largeRichMoisturizer]: 'For normal-to-dry skin',
  [ITEM_IDS.largeMoisturizer]: 'For normal-to-oily skin',
  [ITEM_IDS.largeCleanser]: 'Lightly foaming face wash',
  [ITEM_IDS.largeAcneCleanser]: 'Gentle clearing face wash',
  [ITEM_IDS.originalLipBalm]: 'unflavored, classic',
  [ITEM_IDS.mintLipBalm]: 'cool, energizing, fresh',
  [ITEM_IDS.vanillaLipBalm]: 'cozy, subtle, sweet',
  [ITEM_IDS.passionFruitLipBalm]: 'bright, surprising, tropical',
  [ITEM_IDS.spfLipBalm]: 'broad-spectrum UV defense ',
};

export type NonSetKeyType = Extract<
  keyof typeof ITEM_NAMES,
  | 'smallCustomFormula'
  | 'largeCustomFormula'
  | 'smallCustomFormulaWithDsf'
  | 'largeCustomFormulaWithDsf'
  | 'smallCleanser'
  | 'largeCleanser'
  | 'smallSpotPatches'
  | 'largeSpotPatches'
  | 'smallAcneBodyWash'
  | 'largeAcneBodyWash'
  | 'smallSunscreen'
  | 'largeSunscreen'
  | 'originalLipBalm'
  | 'mintLipBalm'
  | 'passionFruitLipBalm'
  | 'spfLipBalm'
  | 'vanillaLipBalm'
  | 'funfettiLipBalm'
  | 'sugarAndSpiceLipBalm'
  | 'smallMicellarMakeupRemover'
  | 'largeMicellarMakeupRemover'
  | 'smallCurologyFutureProof'
  | 'largeCurologyFutureProof'
  | 'smallDeepCleanse'
  | 'largeDeepCleanse'
  | 'smallFutureFormula'
  | 'largeFutureFormula'
  | 'smallDarkSpotFormula'
  | 'largeDarkSpotFormula'
  | 'smallCreamOilCleanser'
  | 'largeCreamOilCleanser'
  | 'smallAcneCleanser'
  | 'largeAcneCleanser'
  | 'hairFormula'
  | 'agencyHairFormula'
  | 'largeDarkSpotSerum'
  | 'futureFormula9g'
  | 'largeHydroPlusEyeGel'
  | 'largeHydroPlusSpfMoisturizer'
  | 'largeRichRestorationBalm'
  | 'smallHydroPlusGelCreamMoisturizer'
  | 'largeHydroPlusGelCreamMoisturizer'
  | 'smallHydroPlusFoamingGelCleanser'
  | 'largeHydroPlusFoamingGelCleanser'
>;

export type ItemNameType = typeof ITEM_NAMES[ItemKeyType];
