import Cookies, { CookieAttributes } from 'js-cookie';

import errorReporter from 'jsx/utils/errorReporter';
import CONFIG from 'jsx/constants/config';

// 4096 Characters is the max size for a single cookie. Most browsers will allow you to
// store more per domain, but it can vary greatly by vendor.
const MAX_COOKIE_BYTES = 4096;

const secure =
  ['production', 'staging', 'review-app'].indexOf(
    window.CUROLOGY_ENV.APP_ENV,
  ) !== -1;

const DEFAULT_OPTIONS: CookieAttributes = {
  expires: 90,
  secure,
  domain: CONFIG.gatsbyCookieDomain,
};

/** @deprecated TODO GE-3421 */
const cleanupDefaultDomainCookie = (key: string): void => {
  Cookies.remove(key, { ...DEFAULT_OPTIONS, domain: window.location.hostname });
};

const stringByteSize = (string: string): number =>
  encodeURI(string).split(/%(?:u[0-9A-F]{2})?[0-9A-F]{2}|./).length - 1;

const get = (key: string): string | undefined => Cookies.get(key);

const set = (
  key: string,
  value: string | string[],
  options: CookieAttributes = {},
): void => {
  const newCookieBytes = stringByteSize(`${key}=${value}`);

  // Error if set try to set too large a cookie
  if (newCookieBytes > MAX_COOKIE_BYTES) {
    errorReporter.notifyException(new Error('Cookie exceeds maximum size'));
  }

  cleanupDefaultDomainCookie(key);
  Cookies.set(key, value, { ...DEFAULT_OPTIONS, ...options });

  // May want to warn if we detect a dropped cookie in the future.
};

const remove = (key: string): void => {
  cleanupDefaultDomainCookie(key);
  Cookies.remove(key, DEFAULT_OPTIONS);
};

const has = (key: string): boolean => !!get(key);

export default {
  get,
  has,
  remove,
  set,
};
