export const SHIPMENT_ORIGIN = {
  SD: 'SD',
  STL: 'STL',
  PIT: 'PIT',
  LGX: 'LGX',
} as const;

export type ShipmentOriginIds = 1 | 2 | 3 | 4;

export const IDS_TO_SHIPMENT_ORIGIN_MAP: Record<
  ShipmentOriginIds,
  ShipmentOriginType
> = {
  1: 'SD',
  2: 'STL',
  3: 'PIT',
  4: 'LGX',
} as const;

export const SHIPMENT_ORIGIN_TO_IDS: Record<
  ShipmentOriginType,
  ShipmentOriginIds
> = {
  SD: 1,
  STL: 2,
  PIT: 3,
  LGX: 4,
} as const;

export const VALID_SHIPMENT_ORIGIN_IDS = Object.values(SHIPMENT_ORIGIN_TO_IDS);

export type ShipmentOriginType = valueof<typeof SHIPMENT_ORIGIN>;

export interface ShipmentOriginData {
  id: ShipmentOriginIds;
  abbreviation: ShipmentOriginType;
}

export const SHIPMENT_ORIGIN_TIME_ZONES: {
  [origin in keyof typeof SHIPMENT_ORIGIN]: string;
} = {
  [SHIPMENT_ORIGIN.SD]: 'America/Los_Angeles',
  [SHIPMENT_ORIGIN.STL]: 'America/Chicago',
  [SHIPMENT_ORIGIN.PIT]: 'America/Detroit',
  [SHIPMENT_ORIGIN.LGX]: 'America/Chicago',
};

export const SHIPMENT_ORIGIN_IS_PHARMACY: {
  [origin in keyof typeof SHIPMENT_ORIGIN]: boolean;
} = {
  [SHIPMENT_ORIGIN.SD]: false,
  [SHIPMENT_ORIGIN.STL]: true,
  [SHIPMENT_ORIGIN.PIT]: true,
  [SHIPMENT_ORIGIN.LGX]: false,
};

export const isShipmentOriginSD = (location: ShipmentOriginType) =>
  location === SHIPMENT_ORIGIN.SD;

export const SHIPMENT_TABS = {
  UPCOMING: 'upcoming shipments',
  UPCOMING_ON_HOLD: 'upcoming shipments - on hold',
  HISTORY: 'shipment history',
} as const;

export type ShipmentTabsType = valueof<typeof SHIPMENT_TABS>;

export const SHIPMENTS_PAGE = 'Shipments Page';

export const MAX_SHIPPING_RATE = 4.95;

export const SHIPMENT_CALLOUT_TYPES = {
  SHIPPING_ADDRESS: 'shippingAddress',
  PAYMENT_METHOD: 'paymentMethod',
  MESSAGE_SUCCESS: 'messageSuccess',
  SUBSCRIPTION_RENEWED: 'subscriptionRenewed',
  SKIPPED_UNTIL: 'skippedUntil',
} as const;

export type ShipmentCalloutTypesType = valueof<typeof SHIPMENT_CALLOUT_TYPES>;
