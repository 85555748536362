import React from 'react';
import { observer } from 'mobx-react';
import { Wordmark } from 'radiance-ui/lib/icons';
import { useTheme } from '@emotion/react';

import URLS from 'jsx/constants/urls';

import {
  OuterContainer,
  InnerContainer,
  NavbarSection,
  RightNavbarSection,
} from './style';

const Navbar = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();

  return (
    <OuterContainer>
      <InnerContainer>
        <NavbarSection>
          <a href={URLS.landingPage} aria-label="Back to Homepage">
            <Wordmark fill={theme.COLORS.primary} />
          </a>
        </NavbarSection>
        <RightNavbarSection>{children}</RightNavbarSection>
      </InnerContainer>
    </OuterContainer>
  );
};

export { NavButton } from './style';
export default observer(Navbar);
