import { action, observable, makeObservable } from 'mobx';

import useStores from 'jsx/utils/hooks/useStores';
import { get } from 'jsx/utils/axiosWrapper';
import URLS from 'jsx/constants/urls';
import { Initializable } from 'jsx/mixins';
import BaseStore from 'jsx/stores/baseStore';

export default class FeatureFlagStore extends Initializable(BaseStore) {
  @observable features: FeaturesType = {};

  @action onInitialize = () => this.fetch();

  @action
  fetch = () =>
    get(URLS.api.featureFlags()).then((res) => {
      this.features = res.data;
    });

  hasFeatureFlag = (featureName: string) => !!this.features[featureName];

  constructor() {
    super();
    makeObservable(this);
  }
}

export interface FeaturesType {
  [feature: string]: boolean;
}

export const useFeatureFlagStore = (): {
  featureFlagStore: FeatureFlagStore;
} => {
  const { featureFlagStore } = useStores();

  return { featureFlagStore };
};

export const useIsFeatureFlagActive = (featureFlag: string) => {
  const { featureFlagStore } = useFeatureFlagStore();

  return featureFlagStore.hasFeatureFlag(featureFlag);
};
