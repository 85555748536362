import { BRANDS, BrandsType } from 'jsx/constants/brands';
import { Brand } from 'jsx/utils/brand';

function isAgency(name: BrandsType): name is typeof BRANDS['agency'] {
  return name === BRANDS.agency;
}

export function isCurology(
  name: BrandsType,
): name is typeof BRANDS['curology'] {
  return name === BRANDS.curology;
}

const getBrand = (patientBrand?: Brand | null) =>
  patientBrand ?? Brand.fromRequestBrand();

export interface BrandedContentProps<T, U> {
  agency: T;
  curology: U;
  patientBrand?: Brand | null;
}

/**
 * Type definition relies heavily on generics and so works best
 * when used with the `as const` directive. This is not necessary,
 * though, especially for simple cases.
 *
 * @example
 *
 * const content = getBrandedContent({
 *   agency: {
 *     formula: 'agency formula',
 *   },
 *   curology: {
 *     formula: 'curology formula',
 *     additionalContent: 'disclaimer'
 *   }
 * } as const)
 */

function getBrandedContent<T, U>({
  agency,
  curology,
  patientBrand,
}: BrandedContentProps<T, U>) {
  const brand = getBrand(patientBrand);

  if (isAgency(brand.name) && agency !== undefined) {
    return agency;
  }

  if (isCurology(brand.name) && curology !== undefined) {
    return curology;
  }

  throw new Error('No brand and content found');
}

export { getBrandedContent };
