import CONFIG from 'jsx/constants/config';
import {
  BrandDisplayNamesType,
  BrandDomainsType,
  BrandsType,
  BRANDS,
  BRAND_DISPLAY_NAMES,
  BRAND_DOMAINS,
  BRAND_THEMES,
  ALL_BRANDS,
} from 'jsx/constants/brands';

export class Brand {
  readonly name: BrandsType;

  constructor(brand: BrandsType) {
    this.name = brand;
  }

  get displayName(): BrandDisplayNamesType {
    return BRAND_DISPLAY_NAMES[this.name];
  }

  get domain(): BrandDomainsType {
    return BRAND_DOMAINS[this.name];
  }

  get theme() {
    return BRAND_THEMES[this.name];
  }

  get isAgency() {
    return this.name === BRANDS.agency;
  }

  get isCurology() {
    return this.name === BRANDS.curology;
  }

  /**
   * Unit test compatibility may require using Brand.fromRequestBrand()
   * instead of REQUEST_BRAND in logic being tested, since the value of
   * REQUEST_BRAND (as determined by the value of CONFIG.appBrand) cannot
   * be modified directly in unit tests when testing brand differences.
   */
  static fromRequestBrand(): Brand {
    // TODO (MJA): unify types
    return new Brand(CONFIG.appBrand);
  }

  static all(): Brand[] {
    return ALL_BRANDS.map((name) => new Brand(name));
  }
}

/**
 * Export an instance that uses request's brand (Config.appBrand). This should
 * typically only be used on the patient side, where the brand can be
 * determined by the URL's host. On the admin side you will typically
 * want to use the class export above and construct it using the patient's
 * brand_id instead, as the URL's host will always be app.curology.com.
 */
export const REQUEST_BRAND = Brand.fromRequestBrand();
