import ReactDOM from 'react-dom';
import React from 'react';
import { observer } from 'mobx-react';

import AlertList from 'jsx/layout/alerts/alertList';

const alertRoot =
  document.querySelector('#reactPortalSection') || document.body;

class Alerts extends React.Component {
  componentDidMount() {
    this.node.className = 'alertsPortal';
    this.node.style.cssText = 'position: relative; z-index: 999999995;';
    alertRoot.appendChild(this.node);
  }

  componentWillUnmount() {
    alertRoot.removeChild(this.node);
  }

  node = document.createElement('div');

  render() {
    return ReactDOM.createPortal(<AlertList />, this.node);
  }
}

export default observer(Alerts);
