import cookie from 'jsx/utils/cookie';

/**
 * Detect if localStorage is supported so we can fall back to cookies if necessary.
 * @return Boolean
 */
function isLocalStorageSupported(): boolean {
  try {
    window.localStorage.setItem('test', 'test');
    window.localStorage.removeItem('test');
    return true;
  } catch (e) {
    return false;
  }
}

function isSessionStorageSupported(): boolean {
  try {
    window.sessionStorage.setItem('test', 'test');
    window.sessionStorage.removeItem('test');
    return true;
  } catch (e) {
    return false;
  }
}

const hasLocalStorage = isLocalStorageSupported();

export const hasSessionStorage = isSessionStorageSupported();

function get(key: string): string | null | undefined {
  if (hasLocalStorage) {
    return window.localStorage.getItem(key);
  }
  return cookie.get(key);
}

function set(key: string, value: string): string | void {
  if (hasLocalStorage) {
    return window.localStorage.setItem(key, value);
  }
  return cookie.set(key, value);
}

function remove(key: string): void {
  if (hasLocalStorage) {
    return window.localStorage.removeItem(key);
  }
  return cookie.remove(key);
}

const storage = {
  get,
  set,
  remove,
};

export default storage;
