// Use a non-null flag value here to override for local testing (see src/utils/featureFlagsAndMetrics::getFlagOverride)
export type FlagValue = string | boolean | number | object | null | undefined;

export const LD_FLAGS = {
  'agency-cookie-consent-banner': undefined,
  'agency-eye-formula-master': undefined,
  'agency-eye-formula-recommendation': undefined,
  'agency-eye-formula-sign-up-flow': undefined,
  'agency-hydroserum-eye-stick': undefined,
  'agency-milky-hydration-concentrate': undefined,
  'agency-non-free-intro-offer': undefined,
  'agency-silkscreen': undefined,
  'agency-silkscreen-sign-up-flow': undefined,
  'agency-silkscreen-recommendation': undefined,
  'agency-small-bottles-in-subscription': undefined,
  'agency-social-login': undefined,
  'agency-social-signup': undefined,
  'agency-suf-eligibility': undefined,
  'agency-resubscription-payments-page-images': undefined,
  'agency-rich-restoration-balm': undefined,
  'agency-two-rx-trial': undefined,
  'bottle-labels-next': undefined,
  'cancellation-flow-discount': undefined,
  'curology-body-cleanse-rx': undefined,
  'curology-cancellation-deep-cleanse-rx': undefined,
  'curology-cookie-consent-banner': undefined,
  'curology-dark-spot-serum': undefined,
  'curology-flow-resubscription-non-expedited': undefined,
  'curology-flow-signup': undefined,
  'curology-flow-signup-chia': undefined,
  'curology-hydro-eye-gel': undefined,
  'curology-hydro-plus-cleanser-and-moisturizer': undefined,
  'curology-micellar-makeup-remover-checkout': undefined,
  'curology-multi-rx-check-in': undefined,
  'curology-multi-rx-mild-acne-upsell': undefined,
  'curology-multi-rx': undefined,
  'curology-1xrx-reorder': undefined,
  'curology-manage-subscription': undefined,
  'curology-promotions-nts-chia': undefined,
  'curology-promotions-welcome-offer-chia': undefined,
  'curology-subscription-cadence-flexibility': undefined,
  'dashboard-v3': undefined,
  'facebook-permanent-deactivate-fb-auth': undefined,
  'large-future-proof-item-name': undefined,
  'medical-treatment-service-no-subscriptions': undefined,
  'recommend-hydro-tret': undefined,
  'resubscription-payments-page-button-copy': undefined,
  'resubscription-payments-page-images': undefined,
  'show-preferred-language': undefined,
  'sss-to-strive': 'true',
};

export type Flag = keyof typeof LD_FLAGS;
