import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import ReactDOM from 'react-dom';

import { RadianceThemeProvider } from 'jsx/components/radianceThemeProvider';
import { applyAccessibilityChecks } from 'jsx/utils/applyAccessibilityChecks';
import stores from 'jsx/auth/stores';

import App from './app';

const renderApp = () => {
  ReactDOM.render(
    <Provider {...stores}>
      <RadianceThemeProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </RadianceThemeProvider>
    </Provider>,
    document.getElementById('main-content'),
  );
};

applyAccessibilityChecks(renderApp);
