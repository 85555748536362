import React from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import NavbarStyles from 'jsx/signup/components/navbar';
import URLS from 'jsx/auth/constants/urls';

import { NavLink, NavExternalLink } from './style';

const Navbar = () => {
  const location = useLocation();

  const isLogin = location.pathname.startsWith(URLS.login);

  return (
    <NavbarStyles>
      {isLogin ? (
        <NavExternalLink href={URLS.welcome} aria-label="Get Started">
          Get Started
        </NavExternalLink>
      ) : (
        <NavLink to={URLS.login}>Log In</NavLink>
      )}
    </NavbarStyles>
  );
};

export default observer(Navbar);
