import React from 'react';
import { observer } from 'mobx-react';
import { DialogModal } from 'radiance-ui';

import { useDialogModalStore } from 'jsx/stores/dialogModalStore';

const DialogModals = (): JSX.Element | null => {
  const { dialogModalStore } = useDialogModalStore();

  if (!dialogModalStore.showModal) {
    return null;
  }

  return (
    <DialogModal
      onClose={dialogModalStore.onCloseIconClick}
      title={dialogModalStore.title}
    >
      {dialogModalStore.modalContent}
    </DialogModal>
  );
};

export default observer(DialogModals);
