import React from 'react';
import { observer } from 'mobx-react';
import { RoundButton } from 'radiance-ui';
import { ArrowRightIcon, ArrowLeftIcon } from 'radiance-ui/lib/icons';

import { SignupNavContainer } from './style';

interface SignupNavigationPropsType {
  backDisabled?: boolean;
  nextDisabled?: boolean;
  onClickBack?: () => unknown;
  onClickNext?: () => unknown;
  backHidden?: boolean;
  nextHidden?: boolean;
  loading?: boolean;
  type?: string;
}

const SignupNavigation: React.FC<SignupNavigationPropsType> = ({
  backDisabled = false,
  nextDisabled = false,
  onClickBack = () => undefined,
  onClickNext = () => undefined,
  backHidden = false,
  nextHidden = false,
  loading = false,
  type = 'button',
}) => (
  <SignupNavContainer backHidden={backHidden}>
    {!nextHidden && (
      <RoundButton
        id="NextButton"
        aria-label="Next"
        onClick={onClickNext}
        isLoading={loading}
        disabled={nextDisabled || nextHidden}
        type={type}
        icon={<ArrowRightIcon />}
      />
    )}
    {!backHidden && (
      <RoundButton
        id="BackButton"
        aria-label="Back"
        disabled={loading || backDisabled || backHidden}
        onClick={onClickBack}
        buttonType="tertiary"
        icon={<ArrowLeftIcon />}
      />
    )}
  </SignupNavContainer>
);

export default observer(SignupNavigation);
