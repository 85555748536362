import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { observer } from 'mobx-react';

import PageRoute from 'jsx/components/pageRoute';
import Layout from 'jsx/auth/layout';
import Loading from 'jsx/components/loading';
import { initLd, updateContext } from 'jsx/utils/featureFlagsAndMetrics';
import { useDecagonChatBot } from 'jsx/utils/decagonChatBot/hooks/useDecagonChatBot';

import URLS from './constants/urls';
import { useEligibilityStore } from './stores/eligibilityStore';
import { useEffectOnce } from '../utils/hooks/useEffectOnce';
import { ensureHooksInitialized } from '../hoc/ensureHooksInitialized';

// Lazy Components
const EmailSaved = React.lazy(
  () =>
    import(
      /* webpackChunkName: "EmailSaved" */
      './views/eligibility/emailSaved'
    ),
);
const DeletingOldAccount = React.lazy(
  () =>
    import(
      /* webpackChunkName: "DeletingOldAccount" */
      './views/eligibility/deletingOldAccount'
    ),
);
const ForgotPassword = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ForgotPassword" */
      './views/forgot-password'
    ),
);
const Login = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Login" */
      './views/login'
    ),
);
const LoginEmail = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LoginEmail" */
      './views/login/email'
    ),
);
const LoginEmailSent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LoginEmailSent" */
      './views/login/email/sent'
    ),
);
const LoginExpired = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LoginExpired" */
      './views/login/expired'
    ),
);
const NotEligible = React.lazy(
  () =>
    import(
      /* webpackChunkName: "NotEligible" */
      './views/eligibility/notEligible'
    ),
);
const RegisterContainer = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Register" */
      './views/eligibility/registerContainer'
    ),
);
const RegisterContainerOtc = React.lazy(
  () =>
    import(
      /* webpackChunkName: "RegisterOtc" */
      './views/eligibility/registerContainerOtc'
    ),
);
const ResetPassword = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ResetPassword" */
      './views/reset-password'
    ),
);
const Welcome = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Welcome" */
      /* webpackPrefetch: true */
      './views/eligibility/welcome'
    ),
);
const Referred = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Referred" */
      './views/referred'
    ),
);

const FunnelBrandTransfer = React.lazy(
  () =>
    import(
      /* webpackChunkName: "FunnelBrandTransfer" */
      './views/eligibility/funnelBrandTransfer'
    ),
);

const FunnelBrandTransferHair = React.lazy(
  () =>
    import(
      /* webpackChunkName: "FunnelBrandTransferHair" */
      './views/eligibility/funnelBrandTransfer/hair'
    ),
);

const NiceToSeeYouAgain = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Login" */
      './views/login/niceToSeeYouAgain'
    ),
);

const ConfirmEligibility = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Welcome" */
      './views/eligibility/confirmEligibility'
    ),
);

const App: React.FC = () => {
  const { eligibilityStore } = useEligibilityStore();

  useEffectOnce(() => {
    initLd(null, eligibilityStore.state);
  });

  useEffect(() => {
    updateContext(null, eligibilityStore.state);
  }, [eligibilityStore.state]);

  useDecagonChatBot();

  return (
    <Layout>
      <React.Suspense fallback={<Loading />}>
        <Switch>
          <PageRoute exact path={URLS.login} component={Login} title="Log in" />
          <PageRoute
            exact
            path={URLS.loginEmail}
            component={LoginEmail}
            title="Log in - Email"
          />
          <PageRoute
            exact
            path={URLS.loginEmailSent}
            component={LoginEmailSent}
            title="Log in - Email - Sent"
          />
          <PageRoute
            exact
            path={URLS.loginExpired}
            component={LoginExpired}
            title="Log in - Expired"
          />
          <PageRoute
            exact
            path={URLS.welcome}
            component={Welcome}
            title="Signup"
          />
          <PageRoute
            exact
            path={URLS.notEligible}
            component={NotEligible}
            title="Availability Notice"
          />
          <PageRoute
            exact
            path={URLS.emailSaved}
            component={EmailSaved}
            title="Availability Notice"
          />
          <PageRoute
            exact
            path={URLS.deletingOldAccount}
            component={DeletingOldAccount}
            title="Deleting old account..."
          />
          <PageRoute
            exact
            path={URLS.register}
            component={RegisterContainer}
            title="Register"
          />
          <PageRoute
            exact
            path={URLS.registerOtc}
            component={RegisterContainerOtc}
            title="Register"
          />
          <PageRoute
            exact
            path={URLS.forgotPassword}
            component={ForgotPassword}
            title="Forgot Password"
          />
          <PageRoute
            exact
            path={URLS.resetPassword}
            component={ResetPassword}
            title="Reset Password"
          />
          <PageRoute
            exact
            path={URLS.get20}
            component={Referred}
            title="Get 20"
          />
          <PageRoute
            title="Signup - Brand Transfer"
            exact
            path={URLS.funnelBrandTransfer}
            component={FunnelBrandTransfer}
          />
          <PageRoute
            title="Signup - Brand Transfer"
            exact
            path={URLS.funnelBrandTransferHair}
            component={FunnelBrandTransferHair}
          />
          <PageRoute
            exact
            path={URLS.niceToSeeYouAgain}
            component={NiceToSeeYouAgain}
            title="Nice to see you again!"
          />
          <PageRoute
            exact
            path={URLS.confirmEligibility}
            component={ConfirmEligibility}
            title="Signup - Eligibility"
          />
        </Switch>
      </React.Suspense>
    </Layout>
  );
};

export default ensureHooksInitialized(useEligibilityStore)(observer(App));
