import styled from '@emotion/styled';

import { ContentContainer } from 'jsx/components/layouts';
import LAYOUT from 'jsx/signup/constants/layout';
import { safeAreaInsetTop } from 'jsx/constants/layout';

export const OuterContainer = styled.nav`
  background-color: ${({ theme }) => theme.COLORS.white};
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.border};
  width: 100%;
  z-index: 1000;
`;

export const InnerContainer = styled(ContentContainer)`
  align-items: center;
  display: flex;
  height: calc(
    ${LAYOUT.navbarHeight} - 1px + ${safeAreaInsetTop}
  ); /* 1px for border */
  justify-content: space-between;
  padding-top: ${safeAreaInsetTop};
`;

export const NavbarSection = styled.section`
  align-items: center;
  display: flex;
  flex: 1 1 calc(100% / 3);
  justify-content: flex-start;
`;

export const RightNavbarSection = styled(NavbarSection)`
  justify-content: flex-end;
  text-align: right;
`;

export const NavButton = styled.button`
  background: transparent;
  border: none;
  padding: 0 10px;
  &:not(.last-of-type) {
    margin-right: 10px;
  }
`;
