export const TREATMENT_CATEGORIES = {
  skin: 'skin',
  hair: 'hair',
} as const;

export const TREATMENT_CATEGORY_DISPLAY_NAMES = {
  skin: 'Skin',
  hair: 'Hair',
} as const;

interface ID_ENTRY {
  [key: string]: number;
}

export const TREATMENT_CATEGORY_IDS: ID_ENTRY = {
  skin: 1,
  hair: 2,
} as const;

export function isTreatmentCategory(
  value: string | null,
): value is TreatmentCategory {
  return value !== null && Object.keys(TREATMENT_CATEGORIES).includes(value);
}

export const allTreatmentCategories = Object.values(TREATMENT_CATEGORIES);
export type TreatmentCategory = valueof<typeof TREATMENT_CATEGORIES>;
export type TreatmentCategoryId = valueof<typeof TREATMENT_CATEGORY_IDS>;
