import { buildAxiosInstance } from './builder';

export * from './interceptors';
export * from './builder';
export * from './errors';

// Create wrapper instance with all default options and request/response interceptors.
const axiosWrapper = buildAxiosInstance();

export const { delete: axiosDelete, get, put, post, patch } = axiosWrapper;

/**
 * If you are making a HTTP request in the patient facing app, you should use this unless
 * there is a specific reason not to.
 *
 * The `axiosWrapper` provides modified versions of Axios' request methods (get, post, ect.)
 * that are augmented with interceptors for automatic error handling. The returned Axios
 * instance can be used in all the same ways as a raw Axios instance.
 *
 * ## Example
 * ``` javascript
 *  import { get, post, axiosDelete } from 'utils/axiosWrapper'
 *
 *  const getResponse = await get('api/foo')
 *  const postResponse = await post('api/foo', { foo: "bar" })
 *  const deleteResponse = await axiosDelete('api/foo/1')
 * ```
 */
export default axiosWrapper;
