import { primaryTheme, secondaryTheme } from 'radiance-ui/lib/constants';

import CurologyLogo from 'jsx/svgs/curologyLogo';
import AgencyLogo from 'jsx/svgs/agencyLogo';

import {
  AGENCY_FORMULA_TYPE_NAMES,
  CUROLOGY_FORMULA_TYPE_NAMES,
  DARK_SPOT_FORMULA_TYPE,
  EYE_FORMULA_TYPE,
  FUTURE_FORMULA_TYPE,
  HAIR_FORMULA_TYPE,
  PRESCRIPTION_BOTTLE_TYPE,
} from './items';

export const BRANDS = {
  agency: 'agency',
  curology: 'curology',
} as const;

export type BrandsType = valueof<typeof BRANDS>;

export const ALL_BRANDS = Object.values(BRANDS);

export const BRAND_DISPLAY_NAMES = {
  agency: 'Agency',
  curology: 'Curology',
} as const;

export type BrandDisplayNamesType = valueof<typeof BRAND_DISPLAY_NAMES>;

export const BRAND_DOMAINS = {
  curology: 'curology.com',
  agency: 'withagency.com',
} as const;

export interface BrandSelection {
  name: BrandsType;
  displayName: string;
  logo: JSX.Element;
}

/*  #TODO (potential) - Resource Logo SVG's to Radiance: */
export const BRAND_SELECTIONS: BrandSelection[] = [
  {
    name: BRANDS.curology,
    displayName: BRAND_DISPLAY_NAMES.curology,
    logo: CurologyLogo,
  },
  {
    name: BRANDS.agency,
    displayName: BRAND_DISPLAY_NAMES.agency,
    logo: AgencyLogo,
  },
];

export type BrandDomainsType = valueof<typeof BRAND_DOMAINS>;

export const BRAND_THEMES = {
  curology: primaryTheme,
  agency: secondaryTheme,
} as const;

// TODO (MJA): Update usages to map type -> name directly instead of using
// getBrandedContent and this object.
export const BRANDS_FORMULAS_TITLE = {
  agency: {
    formulaTitle: AGENCY_FORMULA_TYPE_NAMES[FUTURE_FORMULA_TYPE],
    darkSpotTitle: AGENCY_FORMULA_TYPE_NAMES[DARK_SPOT_FORMULA_TYPE],
    eyeFormulaTitle: AGENCY_FORMULA_TYPE_NAMES[EYE_FORMULA_TYPE],
    hairTitle: AGENCY_FORMULA_TYPE_NAMES[HAIR_FORMULA_TYPE],
  },
  curology: {
    formulaTitle: CUROLOGY_FORMULA_TYPE_NAMES[PRESCRIPTION_BOTTLE_TYPE],
    // TODO (ADSC): Update this to use ADSC when feature flag is active
    darkSpotTitle: AGENCY_FORMULA_TYPE_NAMES[DARK_SPOT_FORMULA_TYPE],
    hairTitle: CUROLOGY_FORMULA_TYPE_NAMES[HAIR_FORMULA_TYPE],
  },
} as const;
