import canonical from '@segment/canonical';
import url from 'component-url';
import { sha256 } from 'js-sha256';
import moment from 'moment';

import type NullUserStore from 'jsx/auth/stores/nullUserStore';
import { SocialProviderType } from 'jsx/auth/types';
import COOKIE_KEYS from 'jsx/constants/cookieKeys';
import { FlagValue } from 'jsx/constants/featureFlagsAndMetrics';
import PatientModel from 'jsx/models/patient';
import SubscriptionItemModel from 'jsx/models/subscription/subscriptionItem';
import { AdminCurrentUserType } from 'jsx/stores/adminCurrentUserStore';
import type BaseUserStore from 'jsx/stores/baseUserStore';
import {
  TREATMENT_CATEGORIES,
  TREATMENT_CATEGORY_IDS,
} from 'jsx/types/treatmentCategories';
import { REQUEST_BRAND } from 'jsx/utils/brand';
import cookie from 'jsx/utils/cookie';
import { capitalize } from 'jsx/utils/strings';

import { trackCustomFeatureFlagEvent } from '../featureFlagsAndMetrics';
import { isDefined } from '../isDefined';
import { normalizePhoneNumber } from '../normalizePhoneNumber';

export { default as EVENT_NAMES } from './eventNames';

// The datetime format you should use for analytics data.
// One of our integrations (Iterable) cannot process
// ISO8601 formatted datetimes.
export const EVENT_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss Z';

interface EventObject {
  event: string;
  [key: string]: unknown;
}

const pushToDataLayer = (eventObject: EventObject): void => {
  // Reset the dataLayer so properties are not recursively merged with
  // the properties of any events that were pushed previously.
  // See https://github.com/google/data-layer-helper
  window.dataLayer.push({ event: 'Reset DataLayer', properties: null });
  window.dataLayer.push(eventObject);
};

interface ExtractedPatientData
  extends Pick<
    PatientModel,
    | 'id'
    | 'brand'
    | 'email'
    | 'firstname'
    | 'lastname'
    | 'address1'
    | 'address2'
    | 'city'
    | 'zipcode'
    | 'phone'
    | 'sessionId'
  > {
  hashedEmail: string;
  hashedPhone: string;
}

// Variables defined here are to be used by GTM's dataLayer.
// Only extract the variables needed so we don't expose
// the entire patient model, which may include phi, etc.
const extractPatientData = (patient: PatientModel): ExtractedPatientData => {
  const normalizedPhone = patient.phone
    ? normalizePhoneNumber(patient.phone)
    : '';

  const data = {
    id: patient.id,
    brand: patient.brand,
    email: patient.email,
    hashedEmail: sha256(patient.email),
    firstname: patient.firstname,
    lastname: patient.lastname,
    address1: patient.address1,
    address2: patient.address2,
    city: patient.city,
    zipcode: patient.zipcode,
    phone: patient.phone,
    hashedPhone: sha256(normalizedPhone),
    sessionId: patient.sessionId,
  } as const;

  const keys = Object.keys(data) as Array<keyof typeof data>;

  return keys.reduce((properties, key) => {
    const val = data[key];

    if (isDefined(val)) {
      // @ts-expect-error -- BRANDS is widened to `string` automatically
      // eslint-disable-next-line no-param-reassign
      properties[key] = val;
    }

    return properties;
  }, {} as ExtractedPatientData);
};

/**
 * Some scripts in Google Tag Manager need to access the current user's ID
 * or hashed email. We push them to the window to support that. We should
 * only push the bare minimum of data to the window!
 */
const appendCurrentPatientToWindow = ({
  id,
  hashedEmail,
  hashedPhone,
  sessionId,
}: ExtractedPatientData): void => {
  // Check for SSR, testing without JSDOM, etc.
  if (typeof window !== 'undefined') {
    window.__analyticsCurrentUser = {
      id,
      hashedEmail,
      hashedPhone,
      sessionId,
    };
  }
};

export const trackEvent = (event: string, properties = {}): void => {
  pushToDataLayer({ event, properties });
  trackCustomFeatureFlagEvent(event, properties);
};

export const trackQuizCompletion = (patient: PatientModel): void => {
  trackEvent('Quiz Completed', { patient: extractPatientData(patient) });
};

const trackFieldEvent =
  (eventName: string) =>
  (field: string, orderNumber = 0, value = '') => {
    trackEvent(eventName, {
      field,
      orderNumber,
      value,
    });
  };
export const trackFieldEntered = trackFieldEvent('Field Entered');
export const trackFieldFocused = trackFieldEvent('Field Focused');

interface TraitsType {
  experiments?: {
    [name: string]: string;
  };
  lastSeenAt?: string;
}

interface IdentifyUserOptions {
  patient: PatientModel;
  traits?: TraitsType;
  callback?: Function;
}

export const identifyUser = ({
  patient,
  traits = {},
  callback,
}: IdentifyUserOptions): void => {
  const patientData = extractPatientData(patient);

  pushToDataLayer({
    event: 'Identify',
    patient: patientData,
    traits,
  });

  appendCurrentPatientToWindow(patientData);

  if (typeof callback === 'function') {
    setTimeout(callback, 300);
  }
};

type ExtractedAdminUserData = Pick<
  AdminCurrentUserType,
  'id' | 'email' | 'fullName'
> & {
  roles?: string[];
};

export const getAmplitudeDeviceId = (): string | false | undefined => {
  if (typeof window === 'undefined') {
    return false;
  }

  // TODO: Remove getInstance() line once SDK is upgraded.
  return (
    window.amplitude?.getInstance?.()?.options?.deviceId ??
    window.amplitude?.getDeviceId?.() ??
    false
  );
};

export const extractAdminUserData = ({
  id,
  email,
  fullName,
  roles,
}: AdminCurrentUserType): ExtractedAdminUserData => ({
  id,
  email,
  fullName,
  roles: roles.map(({ name }) => name),
});

export const identifyAdminUser = (user: AdminCurrentUserType): void => {
  pushToDataLayer({
    event: 'Identify',
    traits: extractAdminUserData(user),
    // this is how our amplitude integration is set up in GTM
    patient: user,
  });
};

export const clearUser = (): void => {
  trackEvent('Logout');
};

const canonicalPath = (): string => {
  const canon = canonical();

  if (!canon) {
    return window.location.pathname;
  }

  const parsed = url.parse(canon);
  return parsed.pathname;
};

const canonicalUrl = (search: string): string => {
  const canon = canonical();

  if (canon) {
    return canon.indexOf('?') !== -1 ? canon : canon + search;
  }

  const uri = window.location.href;
  const index = uri.indexOf('#');
  return index === -1 ? uri : uri.slice(0, index);
};

// see https://segment.com/docs/spec/page/#properties
// and https://github.com/segmentio/analytics.js-core/blob/2facc5f327b84513ebbede2e5392483ea654f117/lib/pageDefaults.js
export const pageDefaults = (): {
  path: string;
  referrer: string;
  search: string;
  url: string;
  host: string;
} => ({
  path: canonicalPath(),
  referrer: document.referrer,
  search: window.location.search,
  url: canonicalUrl(window.location.search),
  host: window.location.hostname,
});

export const trackPage = (name: string, properties = {}): void => {
  trackEvent('Loaded a Page', {
    treatment_categories: cookie.get(COOKIE_KEYS.treatmentCategories),
    treatment_category_ids: cookie.get(COOKIE_KEYS.treatmentCategoryIds),
    ...pageDefaults(),
    ...properties,
    ...{ name },
  });
};

export const trackEventWithPath = (event: string): void => {
  trackEvent(event, { path: canonicalPath() });
};

export const trackAfterRegistration = (patient: PatientModel): void => {
  identifyUser({ patient });

  trackEvent('Signup Quiz Started', {
    patient: extractPatientData(patient),
  });
};

export const trackRegistration = (patient: PatientModel): void => {
  identifyUser({ patient });
  trackEvent('Account Registered', {
    fbEventId: `account_registered_${patient.id}`,
    treatmentCategories: TREATMENT_CATEGORIES.skin,
    treatmentCategoryIds: TREATMENT_CATEGORY_IDS.skin,
  });
};

export const trackConversion = (
  patient: PatientModel,
  revenue: string | number,
  invoiceId: string | number,
  referrerSurveyResponse: string | null,
  subscriptionItems: SubscriptionItemModel[],
): void => {
  const products = subscriptionItems.map((item) => ({
    id: item.itemId,
    name: item.name,
  }));
  pushToDataLayer({
    event: 'Order Completed',
    patient: extractPatientData(patient),
    treatment_categories: cookie.get(COOKIE_KEYS.treatmentCategories),
    treatment_category_ids: cookie.get(COOKIE_KEYS.treatmentCategoryIds),
    invoiceId,
    revenue,
    currency: 'USD',
    products,
    referrerSurveyResponse,
  });

  // For Google Analytics ecommerce
  // See https://support.google.com/tagmanager/answer/6107169?hl=en
  pushToDataLayer({
    event: 'Google Analytics Transaction',
    transactionId: invoiceId,
    transactionTotal: revenue,
    transactionProducts: [
      {
        name: 'Curology Subscription',
        sku: '1',
        price: revenue,
        quantity: 1,
      },
    ],
  });
};

export const performPageTrack = (
  userStore: BaseUserStore | NullUserStore,
  pageTitle: string,
): void => {
  if (userStore.isHydrated) {
    identifyUser({
      patient: userStore.patient,
      traits: {
        lastSeenAt: moment().format(EVENT_DATETIME_FORMAT),
      },
    });
  }

  document.title = `${REQUEST_BRAND.displayName} - ${pageTitle}`;
  trackPage(pageTitle);
};

export const trackEligibilityFailure = (eventData: {
  reason: string;
  dob?: string | null;
  state?: string | null;
}) => {
  trackEvent('Eligibility Failed', eventData);
};

export const trackEligibilitySubmission = () => {
  trackEvent('Eligibility Submitted', {
    path: canonicalPath(),
    brand: REQUEST_BRAND.name,
  });
};

export const trackSocialAuthButtonClick = (
  provider: SocialProviderType,
  isSignup: boolean,
) => {
  const actionName = isSignup ? 'Signup' : 'Login';
  trackEvent(`${capitalize(provider)} ${actionName} Clicked`);
};

export const trackFeatureFlagValue = (
  flagName: string,
  flagValue: FlagValue,
) => {
  trackEvent('Launch Darkly Flag Assigned', {
    flagName,
    flagValue,
  });
};
