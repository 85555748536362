import React from 'react';
import { inject } from 'mobx-react';

/**
 * This is a temporary solution to allow us to update
 * to TypeScript, without converting to functional components
 * at the same time.  Functional components and hooks are 100% preferred.
 *
 * This function allows one to create a typed inject mechanism that
 * preserves type information.
 *
 * @deprecated Use functional components + hooks instead
 */
const buildTypedInject = <Stores extends {}>() => {
  return <StoreName extends keyof Stores & string>(
    ...storeNames: StoreName[]
  ) => {
    return inject(...storeNames) as <FullProps extends Pick<Stores, StoreName>>(
      component: React.ComponentType<FullProps>,
    ) => React.ComponentClass<Omit<FullProps, StoreName>>;
  };
};

export default buildTypedInject;
