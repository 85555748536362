import { SPACER } from 'radiance-ui/lib/constants';

import { safeAreaInsetBottom } from 'jsx/constants/layout';

const navbarHeight = SPACER.x5large;
const progressBarHeight = SPACER.xsmall;

export default {
  navbarHeight,
  progressBarHeight,
  remainingPageHeight: `calc(100vh - ${navbarHeight} - ${progressBarHeight} - ${safeAreaInsetBottom})`,
};

export const SIGNUP_QUESTION_LAYOUTS = {
  grid: 'grid',
  stack: 'stack',
};

export type SignupQuestionLayoutsType = valueof<typeof SIGNUP_QUESTION_LAYOUTS>;
