import storage from 'jsx/utils/storage';

/**
 * A "toggle" of sorts for saving local storage key:value pairs
 */
export const setWithNullHandling = (
  key: string,
  reason: string | null | undefined,
) => {
  if (reason === null || reason === undefined) {
    storage.remove(key);
  } else {
    storage.set(key, reason);
  }
};

export const getFromStorage = (key: string) => {
  return storage.get(key) || null;
};

export const removeFromStorage = (key: string) => {
  storage.remove(key);
};
