import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

export const NavLink = styled(Link)`
  text-decoration: none;
`;

export const NavExternalLink = styled.a`
  text-decoration: none;
`;
