import moment from 'moment';

import HolidayStore from 'jsx/stores/holidayStore';

import HolidayDateManager from './HolidayDateManager';

/**
 * This determines that Friday at midnight (patient's time) we consider this almost the weekend.
 */
export function isAlmostWeekend(): boolean {
  const date = new Date();
  return date.getDay() === 5;
}

export function isWeekend(): boolean {
  const date = new Date();
  return [0, 6].includes(date.getDay());
}

// TODO-TS: Unsure about Date as valid argument
export function momentOrNull(
  date: string | Date | null | moment.Moment,
): moment.Moment | null {
  if (!date) {
    return null;
  }
  return moment(date);
}

export function providerResponseTime(): string {
  return '2-3 business days';
}

export function supportResponseTime(holidayStore: HolidayStore): string {
  const dateManager = new HolidayDateManager(holidayStore.holidayDates);
  if (dateManager.nearHoliday()) {
    return '2-4 days (holiday hours)';
  }
  return isAlmostWeekend() || isWeekend() ? '48 hours' : '24 hours';
}
