import React from 'react';
import { observer } from 'mobx-react';
import { ImmersiveModal } from 'radiance-ui';

import { useImmersiveModalStore } from 'jsx/stores/immersiveModalStore';

const ImmersiveModals = () => {
  const { immersiveModalStore } = useImmersiveModalStore();

  if (!immersiveModalStore.showModal) {
    return null;
  }

  return (
    <ImmersiveModal
      onClose={immersiveModalStore.closeModal}
      footerContent={immersiveModalStore.footerContent}
      headerImage={immersiveModalStore.headerImage}
      title={immersiveModalStore.title}
    >
      {immersiveModalStore.modalContent}
    </ImmersiveModal>
  );
};
export default observer(ImmersiveModals);
