import alertStore from 'jsx/stores/alertStore';
import AuthStore from 'jsx/auth/stores/authStore';
import DialogModalStore from 'jsx/stores/dialogModalStore';
import EligibilityStore from 'jsx/auth/stores/eligibilityStore';
import FeatureFlagStore from 'jsx/stores/featureFlagStore';
import ImmersiveModalStore from 'jsx/stores/immersiveModalStore';
import NullUserStore from 'jsx/auth/stores/nullUserStore';
import buildTypedInject from 'jsx/hoc/buildTypedInject';
import UsedReferralStore from 'jsx/stores/usedReferralStore';
import PromotionStore from 'jsx/stores/promotionStore';

const stores = {
  alertStore,
  authStore: new AuthStore(),
  dialogModalStore: new DialogModalStore(),
  eligibilityStore: new EligibilityStore(),
  featureFlagStore: new FeatureFlagStore(),
  immersiveModalStore: new ImmersiveModalStore(),
  promotionStore: new PromotionStore(),
  userStore: new NullUserStore(),
  usedReferralStore: new UsedReferralStore(),
} as const;

export type AuthStores = typeof stores;
export const authTypedInject = buildTypedInject<AuthStores>();

export default stores;
