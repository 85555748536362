import { action, makeObservable, observable } from 'mobx';
import { AxiosResponse } from 'axios';

import { get } from 'jsx/utils/axiosWrapper';
import useStores from 'jsx/utils/hooks/useStores';
import { Initializable } from 'jsx/mixins';
import { OfferType } from 'jsx/api/generated';
import URLS from 'jsx/constants/urls';

import BaseStore from '../baseStore';

/**
 * @see `app/Http/Resources/Patient/Referrals/UsedReferralResource.php`
 */
export interface UsedReferral {
  code: string;
  offer: OfferType;
  creditAmountInDollars: number;
  expiresAt: string | null;
  isExpired: boolean;
  firstName: string;
}

class UsedReferralStore extends Initializable(BaseStore) {
  constructor() {
    super();
    makeObservable(this);
  }

  @observable
  referral: UsedReferral | null = null;

  @action
  onInitialize = () => this.fetchUsedReferral();

  @action
  fetchUsedReferral = () => {
    return get<{ referral: UsedReferral | null }>(URLS.api.usedReferral()).then(
      this.hydrateUsedReferral,
    );
  };

  @action
  hydrateUsedReferral = (
    response: AxiosResponse<{ referral: UsedReferral | null }>,
  ) => {
    this.referral = response.data.referral;
  };
}

export const useUsedReferralStore = (): {
  usedReferralStore: UsedReferralStore;
} => {
  const { usedReferralStore } = useStores();

  return { usedReferralStore };
};

export default UsedReferralStore;
