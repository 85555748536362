import React from 'react';
import { BrandStyles, ResetStyles } from 'radiance-ui';

import Alerts from 'jsx/layout/alerts';
import { PageIntro } from 'jsx/signup/components';
import DialogModals from 'jsx/layout/dialogModals';
import ImmersiveModals from 'jsx/layout/immersiveModals';

import Navbar from './navbar';
import { MainContainer, ContentContainer, PageContainer } from './style';

const Layout: React.FC = ({ children }) => {
  return (
    <React.Fragment>
      <BrandStyles />
      <ResetStyles />
      <Alerts />
      <Navbar />
      <DialogModals />
      <ImmersiveModals />
      <MainContainer>{children}</MainContainer>
    </React.Fragment>
  );
};

export { ContentContainer, PageContainer, PageIntro };

export default Layout;
